.alert-notification--fixed {
    position: fixed;
    bottom: 0;
    right: 0;
    overflow-y: auto;
    /*padding: 1rem;*/
    max-height: 100vh;

    @media screen and (max-width: 767px) {
        left: 0;
        -webkit-overflow-scrolling: touch;
    }

    @media screen and (min-width: 768px) {
        bottom: 0;
        max-height: 100vh;
        padding-left: 0;
        padding-top: 0;
    }
}
.alert-notification__container {
    pointer-events: auto;
    max-width: 100%;
    width: 25rem;
    margin: 0 auto;
}